import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

import { track } from "../utils/APIUtils";
import Loader from "./Loader";
import i18next from "i18next";

function HomePage(props) {

    const { t } = useTranslation();
    let data = {};
    const [isLoading, setLoading] = useState(false);
    const [value, setValue] = useState({ shipId: '', trackingId: '' });
    const [inputEnable, setInputEnable] = useState({ shipId: true, trackingId: true });

    const isAiship = window.location.href.includes("aiship");

    const onChange = (e) => {
        let shipid = true;
        let trackingid = true;
        if (e.target.value !== '' && e.target.id === 'shipment_no') {
            shipid = true;
            trackingid = false;
        } else if (e.target.value !== '' && e.target.id === 'tracking_no') {
            shipid = false;
            trackingid = true;
        } else {
            shipid = true;
            trackingid = true;
        }
        setInputEnable({ shipId: shipid, trackingId: trackingid });

        const numberRegex = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (e.target.id === 'shipment_no' && (e.target.value === '' || numberRegex.test(e.target.value))) {
            setValue({ shipId: e.target.value, trackingId: '' })
        } else if (e.target.id === 'tracking_no') {
            setValue({ shipId: '', trackingId: e.target.value })
        }
    }

    const trackShipment = () => {
        const { history } = props;
        var shipId = document.getElementById('shipment_no').value;
        var trackingNo = document.getElementById('tracking_no').value;
        if (shipId || trackingNo) {
            setLoading(true);
            track(shipId, trackingNo).then(response => {
                setLoading(false);
                data = response.data;
                if (data.error) {
                    toast.error(t("shipment.not.found"), { position: "bottom-center", })
                    history.push({ pathname: '/not-found', state: { ...value, aiship: isAiship } });
                } else {
                    if (props.setData) {
                        props.setData(data);
                    }
                    history.push('/search?' + ((shipId && shipId !== '') ? ('shipId=' + shipId) : '') + ((trackingNo && trackingNo !== '') ? ((shipId && shipId !== '') ? '&' : '') + 'trackingNo=' + trackingNo : ''));
                }
            }).catch(error => {
                setLoading(false);
                toast.error(t("shipment.not.found"), { position: "bottom-center", })
                history.push({ pathname: '/not-found', state: { ...value, aiship: isAiship } });
            });
        } else {
            toast.error(t("shipid.trackingno.validation.error.msg"), { position: "bottom-center", })
        }
    }

    return (
        <div className="row h-100">
            <div className="col-md-10 col-lg-9 col-xl-7 col-12 m-auto pb-4">
                <div className="card mb-3">
                    <div className="card-body p-0">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-md-5 section-container d-flex align-items-center justify-content-center py-4 py-md-0">
                                    <form className="_material-form-group-with-inside-boxshadow px-4">
                                        <div className="d-flex align-items-center justify-content-center pb-4">
                                            {isAiship? (
												<img src={'images/aiship-logo.svg'} alt={"AIShip logo"} />
											) : window.location.href.indexOf("paid") > -1 ? (
												<img src={'images/paidshipping-logo.svg'} alt={"Paid Shipping logo"} />
											) : (
														<img
															src={i18next.language === 'fr' ? 'images/shiptime-logo-white-fr.svg' : 'images/shiptime-logo-white.svg'}
															alt={"ShipTime logo"} />
													)}
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <div className="form-group _material-form-group">
                                                    <input type="text" className="form-control form-control-style"
                                                        id="shipment_no" onChange={onChange} value={value.shipId} disabled={!inputEnable.shipId}
                                                        placeholder={t("type.ship.id")} />
                                                    <label className="mlabel text-white"
                                                        htmlFor="shipment_no">
                                                        <Trans i18nKey="shipment.id">
                                                            Ship ID
                                                        </Trans>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group _material-form-group">
                                                    <input type="text" className="form-control form-control-style"
                                                        id="tracking_no" onChange={onChange} value={value.trackingId} disabled={!inputEnable.trackingId}
                                                        placeholder={t("type.tracking.no")} />
                                                    <label className="mlabel text-white"
                                                        htmlFor="tracking_no">
                                                        <Trans i18nKey="tracking.no">
                                                            Tracking Number
                                                        </Trans>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 text-center mt-3">
                                                <button
                                                    type={"button"}
                                                    className="btn btn-shadow text-white btn-lg"
                                                    onClick={trackShipment}
                                                    style={{ background: '#ed5926' }}>
                                                    <Trans i18nKey="track">
                                                        Track
                                                    </Trans>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-1 d-none d-md-flex align-items-center justify-content-center p-0"
                                    style={{ marginLeft: '-1px', overflow: 'hidden' }}>
                                    <img alt={"Partition saperator"} src="images/branded-tracking-page/saperator.svg"
                                        className="w-100 h-100"
                                        style={{ transform: 'rotate(180deg) scale(1.003222)' }} />
                                </div>
                                <div className="col-6 d-none d-md-flex align-items-center justify-content-center">
                                    <img alt={"Tracking placeholder"}
                                        src="images/branded-tracking-page/tracking-img.svg" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            {
                isLoading && <Loader />
            }
        </div>
    );
}

export default withRouter(HomePage);